import React from 'react';
import {KTIcon} from '../../../_metronic/helpers';
import {useNavigate} from 'react-router-dom';

const PrivacyAndPolicy = () => {
  const navigate = useNavigate();
  return (
    <div className='container py-4'>
      {/* Back Button */}
      <button type='submit' className='btn btn-primary' onClick={() => navigate('/')}>
        <KTIcon iconName='arrow-left' className='fs-4 text-white ms-1' />
        Back
      </button>
      <h1 className='fw-bold fs-1 mb-6 text-center'>Privacy Policy</h1>
      <p className='mb-6 fs-4'>
        <strong>Effective date: August 28, 2024</strong>
      </p>
      <h2 className='fw-bold fs-2 mb-4 link-primary'>Introduction</h2>
      <p className='mb-6 fs-4'>
        At Diibs, we take your privacy seriously. Please read this Privacy Policy to learn how we
        treat your personal data.{' '}
        <b>
          By using or accessing our Services in any manner, you acknowledge that you accept the
          practices and policies outlined below, and you hereby consent that we will collect, use
          and disclose your information as described in this Privacy Policy.
        </b>
      </p>
      <p className='mb-6 fs-4'>
        Remember that your use of Diibs’ Services is at all times subject to our Terms of Use,
        <a href='/terms-and-conditions'>Terms of Use</a>, which incorporates this Privacy Policy.
        Any terms we use in this Policy without defining them have the definitions given to them in
        the Terms of Use.
      </p>
      <p className='mb-6 fs-4'>
        As we continually work to improve our Services, we may need to change this Privacy Policy
        from time to time. We will alert you of material changes by placing a notice on the Diibs
        website, by sending you an email and/or by some other means. Please note that if you’ve
        opted not to receive legal notice emails from us (or you haven’t provided us with your email
        address), those legal notices will still govern your use of the Services, and you are still
        responsible for reading and understanding them. If you use the Services after any changes to
        the Privacy Policy have been posted, that means you agree to all of the changes.
      </p>
      <h2 className='fw-bold fs-2 mb-4 link-primary'>Privacy Policy Table of Contents</h2>
      <ul className='mb-6 fs-4 fw-bold' style={{listStyleType: 'none'}}>
        <li>
          <a href='#what-this-policy-covers' className='link-style'>
            What this Privacy Policy Covers
          </a>
        </li>
        <li>
          <a href='#personal-data' className='link-style'>
            Personal Data
          </a>
          <ul style={{listStyleType: 'none'}} className='fw-bold'>
            <li>
              <a href='#categories-of-personal-data' className='link-style'>
                - Categories of Personal Data We Collect
              </a>
            </li>
            <li>
              <a href='#commercial-or-business-purposes' className='link-style'>
                - Our Commercial or Business Purposes for Collecting Personal Data
              </a>
            </li>
            <li>
              <a href='#permitted-purposes' className='link-style'>
                - Other Permitted Purposes for Processing Personal Data
              </a>
            </li>
            <li>
              <a href='#categories-of-sources' className='link-style'>
                - Categories of Sources of Personal Data
              </a>
            </li>
          </ul>
        </li>
        <li>
          <a href='#how-we-disclose-your-personal-data' className='link-style'>
            How We Disclose Your Personal Data
          </a>
        </li>
        <li>
          <a href='#tracking-tools-advertising-opt-out' className='link-style'>
            Tracking Tools, Advertising, and Opt-Out
          </a>
        </li>
        <li>
          <a href='#data-security' className='link-style'>
            Data Security
          </a>
        </li>
        <li>
          <a href='#personal-data-of-children' className='link-style'>
            Personal Data of Children
          </a>
        </li>
        <li>
          <a href='#contact-information' className='link-style'>
            Contact Information
          </a>
        </li>
      </ul>
      <h2 className='fw-bold fs-2 mb-4 link-primary' id='what-this-policy-covers'>
        What this Privacy Policy Covers
      </h2>
      <p className='mb-6 fs-4'>
        This Privacy Policy covers how we treat Personal Data that we gather when you access or use
        our Services. “Personal Data” means any information that identifies or relates to a
        particular individual and also includes information referred to as “personally identifiable
        information” or “personal information” or “sensitive personal information” under applicable
        data privacy laws, rules or regulations. This Privacy Policy does not cover the practices of
        companies we don’t own or control or people we don’t manage.
      </p>{' '}
      <h2 className='fw-bold fs-2 mb-4 link-primary' id='personal-data'>
        Personal Data
      </h2>
      <p className='mb-6 fs-4' id='categories-of-personal-data'>
        <u>Categories of Personal Data We Collect</u>{' '}
      </p>
      <p className='mb-6 fs-4'>
        This chart details the categories of Personal Data that we collect and have collected over
        the past 12 months:
      </p>
      <div className='table-responsive'>
        <table className='table table-striped table-hover'>
          <thead>
            <tr>
              <th scope='col'>Category of Personal Data (and Examples)</th>
              <th scope='col'>Business or Commercial Purpose(s) for Collection</th>
              <th scope='col'>
                Categories of Third Parties With Whom We Disclose this Personal Data
              </th>
            </tr>
          </thead>
          <tbody className='tableBody'>
            <tr>
              <td>
                Profile or Contact Data such as first and last name, email, phone number, and unique
                identifiers.
              </td>
              <td>
                <ul>
                  <li>Providing, Customizing and Improving the Services</li>
                  <li>Marketing the Services</li>
                  <li>Corresponding with You</li>
                  <li>Providing, Customizing and Improving the Services</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>Service Providers</li>
                  <li>Business Partners</li>
                  <li>Parties You Authorize, Access or Authenticate</li>
                  <li>
                    Service Providers (specifically our payment processing partner, currently
                    Stripe, Inc.)
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                Payment Data such as financial account information, payment card type, last 4 digits
                of payment card, and billing address, phone number, and email.
              </td>
              <td>
                <ul>
                  <li>Providing, Customizing and Improving the Services</li>
                  <li>Marketing the Services</li>
                  <li>Corresponding with You</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>Service Providers</li>
                  <li>Advertising Partners</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>Commercial Data such as purchase history and consumer profiles.</td>
              <td>
                <ul>
                  <li>Providing, Customizing and Improving the Services</li>
                  <li>Corresponding with You</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>Service Providers</li>
                  <li>Advertising Partners</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                Device/IP Data such as IP address, device ID, domain server, and type of device/
                operating system/browser used to access the Services.
              </td>
              <td>
                <ul>
                  <li>Providing, Customizing and Improving the Services</li>
                  <li>Marketing the Services</li>
                  <li>Corresponding with You</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>Service Providers</li>
                  <li>Business Partners</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>Social Network Data such as email, phone number, and user name.</td>
              <td>
                <ul>
                  <li>Providing, Customizing and Improving the Services</li>
                  <li>Marketing the Services</li>
                  <li>Corresponding with You</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>Service Providers</li>
                  <li>Business Partners</li>
                  <li>Parties You Authorize, Access or Authenticate</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                Consumer Demographic Data such as age and/or date of birth, zip code, and gender.
              </td>
              <td>
                <ul>
                  <li>Providing, Customizing and Improving the Services</li>
                  <li>Corresponding with You</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>Service Providers</li>
                  <li>Business Partners</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>Geolocation Data such as GPS data.</td>
              <td>
                <ul>
                  <li>Providing, Customizing and Improving the Services</li>
                  <li>Marketing the Services</li>
                  <li>Corresponding with You</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>Service Providers</li>
                  <li>Business Partners</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                Inferences Drawn From Other Personal Data Collected such as attributes, user
                behavior, and predispositions.
              </td>
              <td>
                <ul>
                  <li>Providing, Customizing and Improving the Services</li>
                  <li>Corresponding with You</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>Service Providers</li>
                  <li>Business Partners</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                Other Identifying Information that You Voluntarily Choose to Provide such as emails,
                letters, texts, or other communications you send us
              </td>
              <td>
                <ul>
                  <li>Providing, Customizing and Improving the Services</li>
                  <li>Corresponding with You</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>Service Providers</li>
                  <li>Business Partners</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p className='mb-6 fs-4' id='commercial-or-business-purposes'>
        <u>Our Commercial or Business Purposes for Collecting Personal Data</u>{' '}
      </p>
      <ul className='mb-6 fs-4'>
        <li>
          <strong>Providing, Customizing and Improving the Services</strong>
          <ul>
            <li>Creating and managing your account or other user profiles.</li>
            <li>Processing orders or other transactions; billing.</li>
            <li>Providing you with the products, services or information you request.</li>
            <li>Meeting or fulfilling the reason you provided the information to us.</li>
            <li>Providing support and assistance for the Services.</li>
            <li>
              Improving the Services, including testing, research, internal analytics and product
              development.
            </li>
            <li>
              Personalizing the Services, website content and communications based on your
              preferences.
            </li>
            <li>Doing fraud protection, security and debugging.</li>
          </ul>
        </li>
        <li>
          <strong>Marketing the Services</strong>
          <ul>
            <li>Marketing and selling the Services.</li>
            <li>
              Showing you advertisements, including interest-based, online behavioral or targeted
              advertising.
            </li>
          </ul>
        </li>
        <li>
          <strong>Corresponding with You</strong>
          <ul>
            <li>
              Responding to correspondence that we receive from you, contacting you when necessary
              or requested, and sending you information about Diibs or the Services.
            </li>
          </ul>
        </li>
      </ul>
      <p className='mb-6 fs-4' id='permitted-purposes'>
        <u>Other Permitted Purposes for Processing Personal Data</u>
      </p>
      <p className='mb-6 fs-4'>
        In addition, each of the above referenced categories of Personal Data may be collected,
        used, and disclosed with the government, including law enforcement, or other parties to meet
        certain legal requirements and enforcing legal terms including: fulfilling our legal
        obligations under applicable law, regulation, court order or other legal process, such as
        preventing, detecting and investigating security incidents and potentially illegal or
        prohibited activities; protecting the rights, property or safety of you, Diibs or another
        party; enforcing any agreements with you; responding to claims that any posting or other
        content violates third-party rights; and resolving disputes.
      </p>
      <p className='mb-6 fs-4'>
        We will not collect additional categories of Personal Data or use the Personal Data we
        collected for materially different, unrelated or incompatible purposes without providing you
        notice or obtaining your consent.
      </p>
      <p className='mb-6 fs-4' id='categories-of-sources'>
        <u> Categories of Sources of Personal Data</u>
      </p>
      <p className='mb-6 fs-4'>
        We collect Personal Data about you from the following categories of sources:
      </p>
      <ul className='mb-6 fs-4'>
        <li>
          <strong>You</strong>
          <ul>
            <li>
              When you provide such information directly to us.
              <ul>
                <li>When you create an account or use our interactive tools and Services.</li>
                <li>
                  When you voluntarily provide information in free-form text boxes through the
                  Services or through responses to surveys or questionnaires.
                </li>
                <li>When you send us an email or otherwise contact us.</li>
              </ul>
            </li>
            <li>
              When you use the Services and such information is collected automatically.
              <ul>
                <li>
                  Through Cookies (defined in the “Tracking Tools, Advertising and Opt-Out” section
                  below).
                </li>
                <li>
                  If you download our mobile application or use a location-enabled browser, we may
                  receive information about your location and mobile device, as applicable.
                </li>
                <li>
                  If you download and install certain applications and software we make available,
                  we may receive and collect information transmitted from your computing device for
                  the purpose of providing you the relevant Services, such as information regarding
                  when you are logged on and available to receive updates or alert notices.
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <h2 className='fw-bold fs-2 mb-4 link-primary' id='how-we-disclose-your-personal-data'>
        How We Disclose Your Personal Data
      </h2>
      <p className='mb-6 fs-4'>
        We disclose your Personal Data to the categories of service providers and other parties
        listed in this section. Depending on state laws that may be applicable to you, some of these
        disclosures may constitute a “sale” of your Personal Data. For more information, please
        refer to the state-specific sections below.
      </p>
      <ul className='mb-6 fs-4'>
        <li>
          <strong>Service Providers</strong>. These parties help us provide the Services or perform
          business functions on our behalf. They include:
          <ul>
            <li>Hosting, technology and communication providers.</li>
            <li>Analytics providers for web traffic or usage of the site.</li>
            <li>Security and fraud prevention consultants.</li>
            <li>Support and customer service vendors.</li>
            <li>Product fulfillment and delivery providers.</li>
            <li>
              Payment processors.
              <ul>
                <li>
                  Our payment processing partner Stripe, Inc. (“Stripe”) collects your
                  voluntarily-provided payment card information necessary to process your payment.
                </li>
                <li>
                  Please see Stripe’s terms of service and privacy policy for information on its use
                  and storage of your Personal Data.
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <strong>Advertising Partners</strong>. These parties help us market our services and
          provide you with other offers that may be of interest to you. They include:
          <ul>
            <li>Ad networks.</li>
            <li>Marketing providers.</li>
          </ul>
        </li>
        <li>
          <strong>Business Partners</strong>. These parties partner with us in offering various
          services. They include:
          <ul>
            <li>Businesses that you have a relationship with.</li>
            <li>
              Companies that we partner with to offer joint promotional offers or opportunities.
            </li>
          </ul>
        </li>
        <li>
          <strong>Parties You Authorize, Access or Authenticate</strong>
          <ul>
            <li>Third parties you access through the services.</li>
            <li>Restaurants</li>
            <li>Social media services.</li>
            <li>Other users.</li>
          </ul>
        </li>
      </ul>
      <p className='mb-6 fs-4'>
        <u> Legal Obligations</u>
      </p>
      <p className='mb-6 fs-4'>
        We may disclose any Personal Data that we collect with third parties in conjunction with any
        of the activities set forth under “Other Permitted Purposes for Processing Personal Data”
        section above.{' '}
      </p>
      <p className='mb-6 fs-4'>
        <u>Business Transfers</u>
      </p>
      <p className='mb-6 fs-4'>
        All of your Personal Data that we collect may be transferred to a third party if we undergo
        a merger, acquisition, bankruptcy or other transaction in which that third party assumes
        control of our business (in whole or in part).{' '}
      </p>
      <p className='mb-6 fs-4'>
        <u>Data that is Not Personal Data</u>
      </p>
      <p className='mb-6 fs-4'>
        We may create aggregated, de-identified or anonymized data from the Personal Data we
        collect, including by removing information that makes the data personally identifiable to a
        particular user. We may use such aggregated, de-identified or anonymized data and disclose
        it with third parties for our lawful business purposes, including to analyze, build and
        improve the Services and promote our business, provided that we will not disclose such data
        in a manner that could identify you.{' '}
      </p>
      <h2 className='fw-bold fs-2 mb-4 link-primary' id='tracking-tools-advertising-opt-out'>
        Tracking Tools, Advertising, and Opt-Out
      </h2>
      <p className='mb-6 fs-4'>
        The Services use cookies and similar technologies such as pixel tags, web beacons, clear
        GIFs and JavaScript (collectively, “Cookies”) to enable our servers to recognize your web
        browser, tell us how and when you visit and use our Services, analyze trends, learn about
        our user base and operate and improve our Services. Cookies are small pieces of data–
        usually text files – placed on your computer, tablet, phone or similar device when you use
        that device to access our Services. We may also supplement the information we collect from
        you with information received from third parties, including third parties that have placed
        their own Cookies on your device(s).
      </p>
      <p className='mb-6 fs-4'>
        Please note that because of our use of Cookies, the Services do not support “Do Not Track”
        requests sent from a browser at this time.
      </p>
      <p className='mb-6 fs-4'>We use the following types of Cookies:</p>
      <ul className='mb-6 fs-4'>
        <li>
          <strong>
            <u>Essential Cookies</u>
          </strong>
          . Essential Cookies are required for providing you with features or services that you have
          requested. For example, certain Cookies enable you to log into secure areas of our
          Services. Disabling these Cookies may make certain features and services unavailable.
        </li>
        <li>
          <strong>
            <u>Functional Cookies</u>
          </strong>
          . Functional Cookies are used to record your choices and settings regarding our Services,
          maintain your preferences over time and recognize you when you return to our Services.
          These Cookies help us to personalize our content for you, greet you by name and remember
          your preferences (for example, your choice of language or region).
        </li>
        <li>
          <strong>
            <u>Performance/Analytical Cookies</u>
          </strong>
          . Performance/Analytical Cookies allow us to understand how visitors use our Services.
          They do this by collecting information about the number of visitors to the Services, what
          pages visitors view on our Services and how long visitors are viewing pages on the
          Services. Performance/Analytical Cookies also help us measure the performance of our
          advertising campaigns in order to help us improve our campaigns and the Services’ content
          for those who engage with our advertising.
        </li>
      </ul>
      <p className='mb-6 fs-4'>
        You can decide whether or not to accept Cookies through your internet browser’s settings.
        Most browsers have an option for turning off the Cookie feature, which will prevent your
        browser from accepting new Cookies, as well as (depending on the sophistication of your
        browser software) allow you to decide on acceptance of each new Cookie in a variety of ways.
        You can also delete all Cookies that are already on your device. If you do this, however,
        you may have to manually adjust some preferences every time you visit our website and some
        of the Services and functionalities may not work.
      </p>
      <p className='mb-6 fs-4'>
        <u>Information about Interest-Based Advertisements</u>
      </p>
      <p className='mb-6 fs-4'>
        We may serve advertisements, and also allow third-party ad networks, including third-party
        ad servers, ad agencies, ad technology vendors, and research firms, to serve advertisements
        through the Services. These advertisements may be targeted to users who fit certain general
        profile categories or display certain preferences or behaviors (“Interest-Based Ads”).
        Information for Interest-Based Ads (including Personal Data) may be provided to us by you,
        or derived from the usage patterns of particular users on the Services and/or services of
        third parties. Such information may be gathered through tracking users’ activities across
        time and unaffiliated properties, including when you leave the Services. To accomplish this,
        we or our service providers may deliver Cookies, including a file (known as a “web beacon”)
        from an ad network to you through the Services. Web beacons allow ad networks to provide
        anonymized, aggregated auditing, research and reporting for us and for advertisers. Web
        beacons also enable ad networks to serve targeted advertisements to you when you visit other
        websites. Web beacons allow ad networks to view, edit or set their own Cookies on your
        browser, just as if you had requested a web page from their site.
      </p>
      <p className='mb-6 fs-4'>
        We comply with the Digital Advertising Alliance (“DAA”) Self-Regulatory Principles for
        Online Behavioral Advertising. Through the DAA and Network Advertising Initiative (“NAI”),
        several media and marketing associations have developed an industry self-regulatory program
        to give consumers a better understanding of, and greater control over, ads that are
        customized based on a consumer’s online behavior across different websites and properties.
        To make choices about Interest-Based Ads from participating third parties, including to
        opt-out of receiving behaviorally targeted advertisements from participating organizations,
        please visit the DAA’s or NAI’s consumer opt-out pages, which are located at
        <a
          href='http://www.networkadvertising.org/choices/'
          target='_blank'
          className='link-padding'
        >
          http://www.networkadvertising.org/choices/
        </a>
        or &nbsp
        <a href='http://www.aboutads.info/choices' target='_blank' className='link-padding'>
          www.aboutads.info/choices
        </a>
        . Users in the European Union should visit the European Interactive Digital Advertising
        Alliance’s user information website
        <a href='http://www.youronlinechoices.eu/' target='_blank' className='link-padding'>
          http://www.youronlinechoices.eu/
        </a>
        .{' '}
      </p>
      <h2 className='fw-bold fs-2 mb-4 link-primary' id='data-security'>
        Data Security
      </h2>
      <p className='mb-6 fs-4'>
        We seek to protect your Personal Data from unauthorized access, use, and disclosure using
        appropriate physical, technical, organizational, and administrative security measures based
        on the type of Personal Data and how we are processing that data. You should also help
        protect your data by appropriately selecting and protecting your password and/or other
        sign-on mechanism; limiting access to your computer or device and browser; and signing off
        after you have finished accessing your account. Although we work to protect the security of
        your account and other data that we hold in our records, please be aware that no method of
        transmitting data over the internet or storing data is completely secure.
      </p>
      <h2 className='fw-bold fs-2 mb-4 link-primary'>Data Retention</h2>
      <p className='mb-6 fs-4'>
        We retain Personal Data about you for as long as necessary to provide you with our Services
        or to perform our business or commercial purposes for collecting your Personal Data. When
        establishing a retention period for specific categories of data, we consider who we
        collected the data from, our need for the Personal Data, why we collected the Personal Data,
        and the sensitivity of the Personal Data. In some cases, we retain Personal Data for longer,
        if doing so is necessary to comply with our legal obligations, resolve disputes or collect
        fees owed, or is otherwise permitted or required by applicable law, rule or regulation. We
        may further retain information in an anonymous or aggregated form where that information
        would not identify you personally.
      </p>
      <p className='mb-6 fs-4'>For example:</p>
      <ul className='mb-6 fs-4'>
        <li>
          We retain your profile information and credentials for as long as you have an account with
          us.
        </li>
        <li>
          We retain your payment data for as long as we need to process your purchase or
          subscription.
        </li>
        <li>
          We retain your device/IP data for as long as we need it to ensure that our systems are
          working appropriately, effectively, and efficiently.
        </li>
      </ul>
      <h2 className='fw-bold fs-2 mb-4 link-primary' id='personal-data-of-children'>
        Personal Data of Children
      </h2>
      <p className='mb-6 fs-4'>
        As noted in the Terms of Use, we do not knowingly collect or solicit Personal Data from
        children under 13 years of age; if you are a child under the age of 13, please do not
        attempt to register for or otherwise use the Services or send us any Personal Data. If we
        learn we have collected Personal Data from a child under 13 years of age, we will delete
        that information as quickly as possible. If you believe that a child under 13 years of age
        may have provided Personal Data to us, please contact us at{' '}
        <a href='mailto:info@gotdiibs.com'>info@gotdiibs.com</a>.
      </p>
      <h2 className='fw-bold fs-2 mb-4 link-primary' id='contact-information'>
        Contact Information
      </h2>
      <p className='mb-6 fs-4'>
        If you have any questions or comments about this Privacy Policy, the ways in which we
        collect and use your Personal Data, or your choices and rights regarding such collection and
        use, please do not hesitate to contact us at:
      </p>
      <ul className='mb-6 fs-4'>
        <li>Gotdiibs.com</li>
        <li>
          <a href='mailto:info@gotdiibs.com'>info@gotdiibs.com</a>
        </li>
        <li>Diibs, Inc., 30 Park Ave, New York City, NY 10016</li>
      </ul>
    </div>
  );
};

export default PrivacyAndPolicy;
