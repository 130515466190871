/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import {App} from '../App';
import {ADMIN_ROUTE, AUTH_ROUTE, DASHBOARD_ROUTE} from '../Helpers';
import {AuthPage, Logout, useAuth} from '../modules/auth';
import {ErrorsPage} from '../modules/errors/ErrorsPage';
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper';
import {AdminRoutes} from './AdminRoutes';
import {RestaurantRoutes} from './RestaurantRoutes';
import {SuperAdminRoutes} from './SuperAdminRoutes';
import {RestaurantStripeRoutes} from './RestaurantStripeRoutes';
import PrivacyAndPolicy from '../pages/PrivacyAndPolicy/PrivacyAndPolicy';
import TermsAndConditions from '../pages/TermsAndConditions/TermsAndConditions';
import {RestaurantOtpVerificationRoutes} from './RestaurantOtpVerificationRoutes';

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env;

const AppRoutes: FC = () => {
  const {currentUser} = useAuth();
  const stripeStatus: any = localStorage?.getItem('loginStatus');
  return (
    <>
      <BrowserRouter basename={PUBLIC_URL}>
        <Routes>
          <Route element={<App />}>
            {currentUser?.role === 'ADMIN' ? (
              <>
                <Route path='/*' element={<AdminRoutes />} />
                <Route index element={<Navigate to={DASHBOARD_ROUTE} />} />
              </>
            ) : currentUser?.role === 'SUPER_ADMIN' ? (
              <>
                <Route path='/*' element={<SuperAdminRoutes />} />
                <Route index element={<Navigate to={ADMIN_ROUTE} />} />
              </>
            ) : currentUser?.role === 'RESTAURANT' ? (
              <>
                {!currentUser?.mobileNumberAdded ? (
                  <>
                    <Route path='/*' element={<RestaurantOtpVerificationRoutes />} />
                    <Route index element={<Navigate to='/send-otp' />} />
                  </>
                ) : (
                  <>
                    <Route path='/*' element={<RestaurantRoutes />} />
                    <Route index element={<Navigate to={DASHBOARD_ROUTE} />} />
                  </>
                )}
              </>
            ) : (
              <>
                <Route path='auth/*' element={<AuthPage />} />
                <Route path='*' element={<Navigate to={AUTH_ROUTE} />} />
                <Route path='privacy-policy' element={<PrivacyAndPolicy />} />
                <Route path='terms-and-conditions' element={<TermsAndConditions />} />{' '}
              </>
            )}
            <Route path='error/*' element={<ErrorsPage />} />
            <Route path='test' element={<DashboardWrapper />} />
            <Route path='logout' element={<Logout />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export {AppRoutes};
