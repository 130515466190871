import {useState} from 'react';
import AsyncSelect from 'react-select/async';

function DaySelection({value, onChange, name, as, onblur, ...otherProps}) {
  const [dayList, setDayList] = useState([
    {id: 1, name: 'Monday', type: 'Monday'},
    {id: 2, name: 'Tuesday', type: 'Tuesday'},
    {id: 3, name: 'Wednesday', type: 'Wednesday'},
    {id: 4, name: 'Thursday', type: 'Thursday'},
    {id: 5, name: 'Friday', type: 'Friday'},
    {id: 6, name: 'Saturday', type: 'Saturday'},
    {id: 7, name: 'Sunday', type: 'Sunday'},
  ]);
  const theme = localStorage.getItem('kt_theme_mode_menu') || 'light';

  const customStyles = {
    control: (base, state) => ({
      ...base,
      color: 'white', // Text color of the control
      backgroundColor: '#1b1b29', // Background color of the control
      borderColor: 'transparent',
    }),
    option: (provided, state) => ({
      ...provided,
      color: 'white', // Text color of the options
      backgroundColor: state.isFocused || state.isSelected ? '#1b1b29' : 'transparent', // Background color of the options
      '&:hover': {
        backgroundColor: '#2b2b40', // Background color on hover
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'white', // Text color of the selected option
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#1b1b29',
    }),
  };
  return (
    <AsyncSelect
      {...otherProps}
      onBlur={onblur}
      name={name}
      defaultOptions={dayList}
      getOptionLabel={(option) => option.name} 
      getOptionValue={(option) => option.name} 
      onChange={onChange}
      value={dayList.find((day) => day.name === value)}
      styles={theme === 'dark' ? customStyles : {}}
    />
  );
}

export default DaySelection;
