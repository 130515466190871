import {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {toast} from 'react-toastify';
import {KTIcon} from '../../../../_metronic/helpers';
import {PageLink, PageTitle} from '../../../../_metronic/layout/core';
import {useThemeMode} from '../../../../_metronic/partials';
import DataTableWrapper from '../../../DataTable';
import {
  ADMIN_ROUTE,
  API_ERROR_MESSAGE,
  TOAST_GENERAL,
  USERS_ROUTE,
  convertUtcToLocal,
} from '../../../Helpers';
import {CustomModal} from '../../../components/Modal';
import StatusSelectBox from '../../../components/Sections/Admin/StatusSelectBox';
import {CommonRowInTable, CommonViewInHeader} from '../../../components/common/DataTableViews';
import {useSessionReducer} from '../../../hook/useSessionReducer';
import {useAuth} from '../../../modules/auth';
import reducerFunc from './action';
import {TInitialState} from './index.d';
const initialState: TInitialState = {
  loading: true,
  data: [],
  totalData: -1,
  isModalOpen: false,
  modalType: 'USER',
  selectedRow: undefined,
  currentPage: 1,
  totalPages: 1,
  error: null,
  search: '',
  changeAttendanceId: null,
  shouldRefreshTable: true,
  verifiedFilter: '',
  statusFilter: '',
  startDate: '',
  endDate: '',
  pageSize: 10,
};

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Users',
    path: '/users',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
];

const adminsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Admin',
    path: '/admin',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
];

export const UsersForAdmin = () => {
  const [state, dispatch] = useSessionReducer<any, any, string>(reducerFunc, initialState, 'USER');
  const [formLoading, setFormLoading] = useState(false);
  const {mode} = useThemeMode();
  const {userModule, currentUser} = useAuth();

  const route = currentUser?.role === 'SUPER_ADMIN' ? ADMIN_ROUTE : USERS_ROUTE;
  const location: any = useLocation();

  let superAminColumns = [
    {
      name: <CommonViewInHeader name='Name' />,
      selector: (row, i) => row.firstName + row.lastName,
      sortable: true,
      width: '200px',
      format: (row, i) => {
        return (
          <CommonRowInTable
            text={row.firstName + ' ' + row.lastName}
            avatar={row?.profilePicture ?? 'media/avatars/blank.png'}
            navigateURL={`${route}/${row.id}`}
            classes='text-dark text-capitalize'
          />
        );
      },
    },
    {
      name: <CommonViewInHeader name='Email' />,
      selector: (row, i) => row.email,
      width: '240px',
      format: (row, i) => {
        return (
          <CommonRowInTable
            navigateURL={`${route}/${row.id}`}
            text={row.email}
            classes='text-dark'
          />
        );
      },
      sortable: true,
    },
    {
      name: <CommonViewInHeader name='Date & Time' />,
      selector: (row, i) => row.created_at,
      width: '200px',
      format: (row, i) => {
        return (
          <CommonRowInTable
            navigateURL={`${route}/${row.id}`}
            text={convertUtcToLocal(row.created_at)}
            classes='text-dark text-uppercase'
          />
        );
      },
      sortable: true,
    },
    {
      name: <CommonViewInHeader name='Address' />,
      sortable: true,
      selector: (row, i) => row.street,
      width: '200px',
      format: (row, i) => {
        return (
          <CommonRowInTable
            navigateURL={`${route}/${row.id}`}
            text={row.street || '-'}
            classes='text-dark text-capitalize'
          />
        );
      },
    },
    {
      name: <CommonViewInHeader name='Status' />,
      // sortable: true,
      selector: (row, i) => row.status,
      width: '200px',
      format: (row, i) => {
        return row?.deletedAt ? (
          <span className='badge capitalize bg-gray-100 px-6 py-3 text-danger'>Deleted</span>
        ) : row.isVerified ? (
          <StatusSelectBox
            value={row.status}
            onChange={(e) => {
              updateTUser(row.id, {status: e.target.value});
              e.target.disabled = true;
            }}
          />
        ) : (
          <span className='form-select-type'>Email not verified</span>
        );
      },
    },

    {
      name: <CommonViewInHeader name='Action' />,
      selector: (row, i) => row.email,
      width: '200px',
      format: (row, i) => {
        return (
          <div className='d-flex align-items-center justify-content-center gap-2'>
            <Link title='View user details' className='linkIcon' to={`${route}/${row.id}`}>
              <KTIcon iconName='eye' className='fs-1' />
            </Link>
            <Link
              title='Edit user details'
              className={`linkIcon px-3 ${row?.deletedAt ? 'cursor-not-allowed' : ''}`}
              to={row?.deletedAt ? '' : `${route}/${row.id}?edit=true`}
              onClick={() => {
                if (row?.deletedAt) {
                  toast.warning('Action not allowed. Admin is deleted.');
                }
              }}
            >
              <KTIcon iconName='pencil' className='fs-1' />
            </Link>
            <span
              title='Delete user'
              className={`linkIcon ${row?.deletedAt ? 'cursor-not-allowed' : ''}`}
              onClick={() => {
                if (row?.deletedAt) {
                  toast.warning('Action not allowed. Admin is deleted.');
                  return;
                }
                dispatch({type: 'SELECT_ROW', payload: row});
                dispatch({type: 'OPEN_MODAL', payload: 'DELETE'});
              }}
            >
              <KTIcon iconName='trash' className='fs-1' />
            </span>
          </div>
        );
      },
      sortable: false,
    },
  ];
  let userColumns = [
    {
      name: <CommonViewInHeader name='Name' />,
      selector: (row, i) => row.firstName + row.lastName,
      sortable: true,
      width: '200px',
      format: (row, i) => {
        return (
          <CommonRowInTable
            text={row.firstName + ' ' + row.lastName}
            navigateURL={`${route}/${row.id}`}
            classes='text-dark text-capitalize'
            onClick={() => {
              localStorage.setItem('path', `${route}/${row.id}`);
            }}
          />
        );
      },
    },
    {
      name: <CommonViewInHeader name='Email' />,
      selector: (row, i) => row.email,
      width: '240px',
      format: (row, i) => {
        return (
          <CommonRowInTable
            navigateURL={`${route}/${row.id}`}
            text={row.email}
            classes='text-dark'
            onClick={() => {
              localStorage.setItem('path', `${route}/${row.id}`);
            }}
          />
        );
      },
      sortable: true,
    },
    {
      name: <CommonViewInHeader name='Address' />,
      sortable: true,
      selector: (row, i) => row.city,
      width: '200px',
      format: (row, i) => {
        return (
          <CommonRowInTable
            navigateURL={`${route}/${row.id}`}
            text={row.city || '-'}
            classes='text-dark text-capitalize'
            onClick={() => {
              localStorage.setItem('path', `${route}/${row.id}`);
            }}
          />
        );
      },
    },
    {
      name: <CommonViewInHeader name='Status' />,
      // sortable: true,
      selector: (row, i) => row.status,
      width: '200px',
      format: (row, i) => {
        return row?.deletedAt ? (
          <span className='badge capitalize bg-gray-100 px-6 py-3 text-danger'>Deleted</span>
        ) : row.isVerified ? (
          <StatusSelectBox
            value={row.status}
            onChange={(e) => {
              updateTUser(row.id, {status: e.target.value});
              e.target.disabled = true;
            }}
          />
        ) : (
          <span className='form-select-type'>Email not verified</span>
        );
      },
    },

    {
      name: <CommonViewInHeader name='Action' />,
      selector: (row, i) => row.email,
      width: '200px',
      format: (row, i) => {
        return (
          <div className='d-flex align-items-center justify-content-center gap-2'>
            <Link title='View user details' className='linkIcon' to={`${route}/${row.id}`}>
              <KTIcon iconName='eye' className='fs-1' />
            </Link>
            <Link
              title='Edit user details'
              className={`linkIcon px-3 ${row?.deletedAt ? 'cursor-not-allowed' : ''}`}
              to={row?.deletedAt ? '' : `${route}/${row.id}?edit=true`}
              onClick={() => {
                if (row?.deletedAt) {
                  toast.warning('Action not allowed. User is deleted.');
                } else {
                  localStorage.setItem('path', `${route}/${row.id}`);
                }
              }}
            >
              <KTIcon iconName='pencil' className='fs-1' />
            </Link>
            <span
              title='Delete user'
              className={`linkIcon ${row?.deletedAt ? 'cursor-not-allowed' : ''}`}
              onClick={() => {
                if (row?.deletedAt) {
                  toast.warning('Action not allowed. User is deleted.');
                  return;
                }
                dispatch({type: 'SELECT_ROW', payload: row});
                dispatch({type: 'OPEN_MODAL', payload: 'DELETE'});
              }}
            >
              <KTIcon iconName='trash' className='fs-1' />
            </span>
          </div>
        );
      },
      sortable: false,
    },
  ];

  const columns = currentUser?.role === 'SUPER_ADMIN' ? superAminColumns : userColumns;

  const getTUsers = async () => {
    dispatch({type: 'LOADING', payload: true});

    const result = await userModule?.getUsers({
      pageNo: state.currentPage ? state.currentPage : 1,
      pageSize: state.pageSize,
      verifiedFilter:
        currentUser?.role === 'ADMIN'
          ? '1'
          : state.statusFilter === 'EMAIL NOT VERIFIED'
          ? '0'
          : state.statusFilter || location?.state?.value
          ? '1'
          : '',
      statusFilter:
        state.statusFilter === 'EMAIL NOT VERIFIED'
          ? ''
          : state.statusFilter
          ? state.statusFilter
          : location?.state?.value,
      searchKeyword: state.search,
      startDate: state.startDate,
      endDate: state.endDate,
    });
    if (result?.success) {
      dispatch({type: 'GET_DATA', payload: result?.data?.rows || []});
      dispatch({type: 'SET_TOTAL_PAGES', payload: result?.data?.count || 1});
    } else {
      dispatch({type: 'GET_DATA', payload: []});
      dispatch({type: 'SET_TOTAL_PAGES', payload: 1});

      if (result?.status !== 600) {
        toast.error(result?.message || API_ERROR_MESSAGE, {
          toastId: TOAST_GENERAL,
        });
      }
    }
  };

  const createTUser = async (userData) => {
    setFormLoading(true);

    const result = await userModule?.createUser({...userData, roleName: 'ADMIN'});
    if (result?.success) {
      toast.success(`Admin created successfully, credentials sent to email.`, {
        toastId: TOAST_GENERAL,
      });
      getTUsers();
      return true;
    } else {
      toast.error(result?.message || `Problem occured, While creating Admin`, {
        toastId: TOAST_GENERAL,
      });
      return false;
    }
  };

  const updateTUser = async (userId, userData) => {
    setFormLoading(true);
    const result = await userModule?.updateUser(userId, userData);
    if (result?.success) {
      toast.success(`User Updated succesfully`, {
        toastId: 'user updated',
      });
      getTUsers();
    } else {
      toast.error(result?.message || API_ERROR_MESSAGE, {
        toastId: TOAST_GENERAL,
      });
    }
    setFormLoading(false);
  };

  const deleteTUser = async (userIds) => {
    dispatch({type: 'CLOSE_MODAL'});
    const result = await userModule?.deleteUsers(userIds);
    if (result?.success) {
      toast.success(
        `${currentUser?.role === 'SUPER_ADMIN' ? 'Admin' : 'User'} Deleted succesfully`,
        {
          toastId: 'user deleted',
        }
      );
      getTUsers();
    } else {
      toast.error(result?.message || API_ERROR_MESSAGE, {
        toastId: TOAST_GENERAL,
      });
    }
  };

  useEffect(() => {
    getTUsers();
  }, [
    state.currentPage,
    state.pageSize,
    state.verifiedFilter,
    state.search,
    state.statusFilter,
    state.startDate,
    state.endDate,
    location?.state?.value,
  ]);

  const deleteItem = function (rows) {
    dispatch({type: 'SELECT_ROW', payload: rows});
    dispatch({type: 'OPEN_MODAL', payload: 'DELETE'});
  };

  const subHeader = (
    <button
      type='button'
      className='btn btn-primary'
      onClick={() => {
        dispatch({type: 'OPEN_MODAL', payload: 'USER'});
      }}
    >
      <KTIcon iconName='plus' className='fs-2' />
      Add admin
    </button>
  );

  let filters: any = {
    label: 'Status',
    options: ['Active', 'Inactive', 'Deleted'].map((t) => ({
      value: t.toUpperCase(),
      label: t,
    })),
    selected: location?.state,
  };

  if (currentUser?.role === 'SUPER_ADMIN') {
    filters = {
      label: 'Status',
      options: ['Active', 'Inactive', 'Email not verified', 'Deleted'].map((t) => ({
        value: t.toUpperCase(),
        label: t,
      })),
    };
  }

  return (
    <>
      <PageTitle>{currentUser?.role === 'ADMIN' ? 'Users List' : 'Admin List'}</PageTitle>
      <DataTableWrapper
        totalData={state.totalData}
        subHeader={currentUser?.role === 'SUPER_ADMIN' && subHeader}
        selectableRows={true}
        loading={state.loading}
        columns={columns}
        data={state.data}
        handleSelected={deleteItem}
        currentPage={state.currentPage}
        totalPages={state.totalPages}
        pageSize={state.pageSize}
        handlePageChange={(page) => dispatch({type: 'PAGE_CHANGE', payload: page})}
        handlePageSizeChange={(pageSize) => dispatch({type: 'SET_PAGE_SIZE', payload: +pageSize})}
        handleSearch={(searchVal) => {
          dispatch({type: 'SEARCH_CHANGE', payload: searchVal});
        }}
        searchValue={state.search}
        filters={{
          dropdown: [filters],
          date: currentUser?.role === 'SUPER_ADMIN',
          preserveType: 'USER',
        }}
        filtersCallback={(values) => {
          dispatch({
            type: 'SET_FILTER',
            payload: {
              statusFilter: values?.Status ? values.Status.value : '',
              verifiedFilter: values['Email verified'] ? values['Email verified'].value : '',
            },
          });
          dispatch({
            type: 'DATE_FILTER',
            payload: {startDate: values.startDate, endDate: values.endDate},
          });
        }}
        isFilterApplied={Boolean(
          state.search ||
            state.statusFilter ||
            state.startDate ||
            state.endDate ||
            state.verifiedFilter
        )}
      />
      {state.isModalOpen && (
        <CustomModal
          data={state.selectedRow}
          handleCancelCallback={() => dispatch({type: 'CLOSE_MODAL'})}
          handleSaveCallback={async (value) => {
            if (state.modalType === 'DELETE') {
              const ids = Array.isArray(state.selectedRow)
                ? state.selectedRow.map((row) => row.id)
                : [state.selectedRow.id];
              deleteTUser({ids: ids});
            } else {
              const result = await createTUser(value);
              if (result) dispatch({type: 'CLOSE_MODAL'});
              else return false;
            }
          }}
          title={
            currentUser?.role === 'ADMIN'
              ? 'Delete User'
              : state.modalType === 'DELETE'
              ? 'Delete Admin'
              : 'Add Admin'
          }
          type={state.modalType}
        />
      )}
    </>
  );
};
