import {useFormik} from 'formik';
import {useEffect, useState} from 'react';
import * as Yup from 'yup';
import {PhoneInput, defaultCountries, parseCountry} from 'react-international-phone';
import 'react-international-phone/style.css';
import {PhoneNumberUtil} from 'google-libphonenumber';
import {useAuth} from '../../../modules/auth';
import {toast, ToastContainer} from 'react-toastify';
import OTPInput from 'react-otp-input';
import DaySelection from '../../common/DaySelection';

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

const countries = defaultCountries.filter((country) => {
  const {iso2} = parseCountry(country);
  return [
    'us',
    'am',
    'au',
    'ua',
    'gb',
    'in',
    'fr',
    'tr',
    'by',
    'ca',
    'ie',
    'is',
    'ht',
    'fi',
    'ar',
    'uk',
  ].includes(iso2);
});

type initialValuesType = {
  phoneNumber: string;
  hostName: string;
  day: string;
};

const initialValues: initialValuesType = {
  phoneNumber: '',
  hostName: '',
  day: '',
};

const validationSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .required('Phone number is required')
    .test('isValidPhoneNumber', 'Phone number is not valid', function (value) {
      return isPhoneValid(value as string);
    }),
  hostName: Yup.string().required('Name is required'),
});

const HostBody = ({
  data,
  handleSaveCallback,
  handleCancelCallback,
  isEditMode,
}: {
  data;
  handleSaveCallback: (value: initialValuesType) => void;
  handleCancelCallback: () => void;
  isEditMode;
}) => {
  console.log('data===', data, isEditMode);

  const {currentUser, restaurantModule} = useAuth();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [otpView, setOtpView] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [otpMessage, setOtpMessage] = useState('');
  const [resendTimer, setResendTimer] = useState(60);
  const [resendLoading, setResendLoading] = useState(false);
  const [otp, setOtp] = useState('');
  const storedValue = localStorage.getItem('hash');
  const otpMobileNumber = localStorage.getItem('mobileNumber');
  const mobileNumberVal = otpMobileNumber ? otpMobileNumber : null;
  const hashKey: any = storedValue ? storedValue : null;
  const [initialMobileNumber, setInitialMobileNumber] = useState(data?.mobileNumber || '');

  useEffect(() => {
    setInitialMobileNumber(data?.mobileNumber || '');
  }, [data]);

  const formik = useFormik({
    initialValues: data
      ? {
          phoneNumber: data.mobileNumber || '',
          hostName: data.hostName || '',
          day: data.day || '',
        }
      : initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const response: any = {
        hostName: values?.hostName,
        mobileNumber: values?.phoneNumber,
        day: values?.day?.toLowerCase(),
        type: 'host',
        otp: otp,
        hash: hashKey,
        restaurantId: data?.restaurantId,
      };

      formik.setSubmitting(true);

      try {
        const otpResponse = isEditMode
          ? await restaurantModule?.editHost(data?.id, response)
          : await restaurantModule?.addHost(response);
        if (otpResponse?.status === 200) {
          toast.success(otpResponse?.message);
          window.location.reload();
          handleCancelCallback();
          // Continue with form submission if OTP is verified
          await handleSaveCallback(response);
        } else {
          toast.error(otpResponse?.message || 'Something went wrong!');
        }
      } catch (error: any) {
        toast.error(error.response?.data?.message || 'Something went wrong!');
      } finally {
        setOtpLoading(false); // Reset loading state
        formik.setSubmitting(false); // Reset form submission state
      }
    },
  });

  const sendOTP = async (event) => {
    event.preventDefault();
    setLoading(true);
    setMessage('');

    try {
      if (!isPhoneValid(formik.values.phoneNumber)) {
        setMessage('Please enter a valid phone number.');
        setLoading(false);
        return;
      }

      const formattedMobileNumber = formik.values.phoneNumber.replace(/^\+/, '');

      const response = await restaurantModule?.sendOtp(String(formattedMobileNumber));
      if (response?.success) {
        setOtpView(true);
        const {hash} = response.data;
        localStorage.setItem('mobileNumber', formattedMobileNumber);
        localStorage.setItem('hash', hash);
        toast.success(response?.message);
      } else {
        toast.error(response?.message || 'Something went wrong!');
      }
    } catch (error: any) {
      toast.error(error.response?.data?.message || 'Something went wrong!');
    }

    setLoading(false);
  };

  // Handle resend timer countdown
  useEffect(() => {
    let interval;
    if (resendTimer > 0) {
      interval = setInterval(() => {
        setResendTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [resendTimer]);

  // Handle OTP resend request
  const handleResendOtp = async () => {
    setResendLoading(true);
    try {
      const response = await restaurantModule?.sendOtp(String(mobileNumberVal));
      if (response?.status === 200) {
        const {hash} = response.data;
        localStorage.setItem('hash', hash);
        toast.success('OTP resent successfully!');
        setResendTimer(60);
      } else {
        toast.error('Something went wrong!');
      }
    } catch (error: any) {
      toast.error(error.response?.data?.message || 'Failed to resend OTP!');
    } finally {
      setResendLoading(false);
    }
  };

  const phoneNumberChanged = formik.values.phoneNumber !== initialMobileNumber;

  return (
    <>
      <form
        id='kt_modal_add_auction_type_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div
          className='d-flex flex-column me-n7 pe-7'
          id='kt_modal_add_auction_type_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_auction_type_header'
          data-kt-scroll-wrappers='#kt_modal_add_auction_type_scroll'
          data-kt-scroll-offset='300px'
        >
          <div>
            <label className='required fw-bold fs-6 mb-2'>Day</label>
            <DaySelection
              as='select'
              {...formik.getFieldProps('day')}
              onChange={(selectedOption) => {
                formik.setFieldValue('day', selectedOption?.name);
              }}
              name='day'
              value={formik.values.day}
              onblur={formik.handleBlur}
            />

            {formik.touched.day && formik.errors.day && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.day as string}</span>
                </div>
              </div>
            )}
          </div>

          <label className='col-form-label required fw-bold fs-6'>Name</label>
          <div className=' fv-row'>
            <input
              type='text'
              className='form-control form-control-lg'
              placeholder='Name'
              {...formik.getFieldProps('hostName')}
              maxLength={50}
            />
            {formik.touched.hostName && formik.errors.hostName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.hostName as string}</div>
              </div>
            )}
          </div>

          <label className='col-form-label required fw-bold fs-6'>Phone Number</label>
          <div>
            <div className='d-flex justify-content-between gap-2 align-items-center fv-row'>
              <div
                className={`${
                  phoneNumberChanged && !otpView ? 'col-lg-10' : otpView ? 'col-lg-12' : 'col-lg-12'
                }`}
              >
                <PhoneInput
                  defaultCountry='fr'
                  value={formik.values.phoneNumber}
                  onChange={(phone) => {
                    formik.setFieldValue('phoneNumber', phone);
                    if (!isPhoneValid(phone)) {
                      if (phone.length > 5) formik.setFieldTouched('phoneNumber', true);
                      formik.setFieldError('phoneNumber', 'Phone number is not valid');
                    } else {
                      formik.setFieldError('phoneNumber', '');
                    }
                  }}
                  countries={countries}
                  inputClassName={'form-control form-control-solid mb-3 mb-lg-0'}
                  inputProps={{
                    name: 'phoneNumber',
                    autoComplete: 'off',
                    disabled: formik.isSubmitting,
                  }}
                />
              </div>
              {phoneNumberChanged && !otpView && (
                <div className='col-lg-4 fv-row'>
                  <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-primary p-2'
                    onClick={sendOTP}
                  >
                    {loading ? 'Sending...' : 'Send OTP'}
                  </button>
                </div>
              )}
            </div>

            {formik.touched.phoneNumber && formik.errors.phoneNumber && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.phoneNumber as string}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        {otpView && (
          <div>
            {/* OTP Input Label */}
            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Enter OTP</label>
            <div className='col-lg-8 fv-row mb-8'>
              <OTPInput
                value={otp}
                onChange={(value) => {
                  setOtp(value);
                  // OTP validation
                  if (value.length < 6) {
                    setOtpMessage('Please enter a 6-digit OTP.');
                  } else if (value.length === 6 && !/^\d+$/.test(value)) {
                    setOtpMessage('Please enter numeric values only.');
                  } else {
                    // Clear message if OTP is valid
                    setOtpMessage('');
                  }
                }}
                numInputs={6}
                shouldAutoFocus
                renderSeparator={<span>-</span>}
                inputStyle={{
                  border: '1px solid transparent',
                  borderRadius: '8px',
                  width: '50px',
                  height: '50px',
                  fontSize: '16px',
                  color: '#000',
                  fontWeight: '600',
                  caretColor: 'blue',
                  background: '#f1f1f1',
                  outline: 'none',
                  textAlign: 'center',
                }}
                renderInput={(props) => <input {...props} />}
              />
              {otpMessage && (
                <div className='fv-plugins-message-container'>
                  <span role='alert' style={{color: 'red'}}>
                    {otpMessage}
                  </span>
                </div>
              )}
            </div>
            {/* end::Form group */}

            {/* begin::Action */}
            <div className='mb-10'>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-primary'
                onClick={handleResendOtp}
                disabled={resendTimer > 0 || resendLoading}
              >
                {resendLoading
                  ? 'Resending...'
                  : resendTimer > 0
                  ? `Resend OTP in ${resendTimer}s`
                  : 'Resend OTP'}
              </button>{' '}
            </div>
            <ToastContainer />
          </div>
        )}

        {/* Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={handleCancelCallback}
            className='btn btn-light me-3'
            disabled={formik.isSubmitting}
          >
            Discard
          </button>

          <button type='submit' className='btn btn-primary'>
            {formik.isSubmitting ? (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            ) : (
              'Verify'
            )}
          </button>
        </div>
      </form>
    </>
  );
};

export default HostBody;
