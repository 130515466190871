import {useFormik} from 'formik';
import {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import * as Yup from 'yup';
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers';
import {
  API_ERROR_MESSAGE,
  EDIT_ERROR_IMG,
  EDIT_USR_ERROR,
  EMAIL_NOT_EDITABLE,
  TOAST_GENERAL,
} from '../../../Helpers';
import {useAuth} from '../../../modules/auth';
export interface IProfileDetails {
  firstName: string;
  lastName: string;
  email: string;
  avatar?: string;
  status: string;
  address: string;
  password: string;
  confirmPassWord: string;
  images?: string;
  imageFile?: undefined | File;
}

const profileDetailsSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(15, 'Maximum 15 characters')
    .required('First Name is required')
    .matches(/^[a-zA-Z]+$/, 'Only characters are allowed'),
  lastName: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(15, 'Maximum 15 characters')
    .required('Last Name is required')
    .matches(/^[a-zA-Z]+$/, 'Only characters are allowed'),
  email: Yup.string().required('Email is required'),
  // address: Yup.string()
  //   .min(3, 'Minimum 3 characters')
  //   .max(200, 'Maximum 200 characters')
  //   .required('Address is required'),
  // city: Yup.string().required('City name is required'),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .max(16, 'Password must be at most 16 characters')
    .matches(/^\S*$/, 'Password cannot contain spaces'),
  confirmPassWord: Yup.mixed()
    .test('match', 'Password must match', function (value) {
      return value === this.parent.password || value === null;
    })
    .nullable(),
});

let initialValues: IProfileDetails = {
  firstName: '',
  lastName: '',
  email: '',
  address: '',
  status: 'ACTIVE',
  password: '',
  confirmPassWord: '',
  avatar: '/media/avatars/300-1.jpg',
  images: '',
  imageFile: undefined,
};

function UserProfileDetails({setUserName, userId, toastId, setLoadDelProfile, loadDelProfile}) {
  const [loading, setLoading] = useState(false);
  const {userModule, currentUser} = useAuth();
  const path: any = localStorage.getItem('path');

  const getTSingleUser = async function () {
    const result = await userModule?.getSingleUser(userId);
    if (result?.success) {
      if (currentUser?.role === 'SUPER_ADMIN') {
        formik.setValues({
          ...result.data,
          images: result?.data?.profilePicture,
          imageFile: undefined,
        });
      } else {
        formik.setValues({
          ...result.data,
          images: result?.data?.profilePicture,
          imageFile: undefined,
          address: `${result?.data?.city || ''}, ${result?.data?.state || ''}, ${
            result?.data?.country || ''
          }`,
        });
      }

      setUserName(`${result.data.firstName} ${result.data.lastName}`);
    } else {
      toast.error(result?.message || API_ERROR_MESSAGE, {
        toastId,
      });
    }
    setLoadDelProfile({loading: false, isDeleted: Boolean(result?.data?.deletedAt)});
  };
  useEffect(() => {
    getTSingleUser();
  }, []);

  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });
  const formik = useFormik<IProfileDetails>({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: async (value) => {
      setLoading(true);

      if (currentUser?.role === 'SUPER_ADMIN') {
        const adminData = new FormData();
        adminData.append('firstName', value.firstName);
        adminData.append('lastName', value.lastName);
        adminData.append('email', value.email);
        adminData.append('address', value.address);
        if (value.imageFile) adminData.append('image', value.imageFile);
        if (value.imageFile === null) adminData.append('profilePicture', '');
        if (value.password) adminData.append('password', value.password);

        await updateTUser(userId, adminData, true);
      } else {
        const userData: any = {
          firstName: value.firstName,
          lastName: value.lastName,
          email: value.email,
          // address: value.address,
        };
        if (value.password) {
          userData.password = value.password;
        }
        await updateTUser(userId, userData);
      }
      setLoading(false);
    },
  });

  const [searchParams] = useSearchParams();
  let isEditMode = searchParams.get('edit') === 'true';
  if (loadDelProfile.isDeleted && isEditMode) {
    isEditMode = false;
    toast.error("User deleted, Can't edit user", toastId);
  }
  const navigate = useNavigate();
  const updateTUser = async (userId, userData, isAdmin = false) => {
    const result = await userModule?.updateUser(userId, userData);

    if (result?.success) {
      toast.success(`User Updated succesfully`, {
        toastId: TOAST_GENERAL,
      });
      navigate(currentUser?.role === 'ADMIN' ? path : '/admin');
    } else {
      toast.error(result?.message || API_ERROR_MESSAGE, {
        toastId: TOAST_GENERAL,
      });
    }
  };

  return (
    <form onSubmit={formik.handleSubmit} noValidate className='form'>
      <div className='card-body  p-9'>
        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label required fw-bold fs-6'>First Name</label>
          <div className='col-lg-8 fv-row'>
            <input
              title={isEditMode ? '' : EDIT_USR_ERROR}
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder='John'
              {...formik.getFieldProps('firstName')}
              maxLength={15}
              disabled={!isEditMode}
            />
            {formik.touched.firstName && formik.errors.firstName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.firstName}</div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label required fw-bold fs-6'>Last Name</label>

          <div className='col-lg-8 fv-row'>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder='Doe'
              {...formik.getFieldProps('lastName')}
              maxLength={15}
              disabled={!isEditMode}
              title={isEditMode ? '' : EDIT_USR_ERROR}
            />
            {formik.touched.lastName && formik.errors.lastName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.lastName}</div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label required fw-bold fs-6'>Email</label>

          <div className='col-lg-8 fv-row'>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid cursor-not-allowed'
              placeholder='Email'
              {...formik.getFieldProps('email')}
              disabled
              title={EMAIL_NOT_EDITABLE}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.email}</div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label required fw-bold fs-6'>Address</label>

          <div className='col-lg-8 fv-row'>
            <textarea
              className='form-control form-control-lg form-control-solid'
              placeholder='Address'
              {...formik.getFieldProps('address')}
              disabled={!isEditMode || currentUser?.role === 'ADMIN'}
              title={isEditMode ? '' : EDIT_USR_ERROR}
            ></textarea>

            {formik.touched.address && formik.errors.address && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.address}</div>
              </div>
            )}
          </div>
        </div>

        {isEditMode && (
          <>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>New Password</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type={showPassword?.password ? 'text' : 'password'}
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Password'
                  {...formik.getFieldProps('password')}
                  disabled={!isEditMode}
                />
                {showPassword?.password ? (
                  <span
                    className='password-toggle-icon'
                    onClick={() =>
                      setShowPassword({...showPassword, password: !showPassword.password})
                    }
                  >
                    <KTIcon iconName='eye' className='fs-2' />
                  </span>
                ) : (
                  <span
                    className='password-toggle-icon'
                    onClick={() =>
                      setShowPassword({...showPassword, password: !showPassword.password})
                    }
                  >
                    <KTIcon iconName='eye-slash' className='fs-2' />
                  </span>
                )}
                {formik.touched.password && formik.errors.password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.password}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                Confirm New Password
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type={showPassword?.confirmPassword ? 'text' : 'password'}
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Confirm Password'
                  {...formik.getFieldProps('confirmPassWord')}
                  disabled={!isEditMode}
                />
                {showPassword?.confirmPassword ? (
                  <span
                    className='password-toggle-icon'
                    onClick={() =>
                      setShowPassword({
                        ...showPassword,
                        confirmPassword: !showPassword.confirmPassword,
                      })
                    }
                  >
                    <KTIcon iconName='eye' className='fs-2' />
                  </span>
                ) : (
                  <span
                    className='password-toggle-icon'
                    onClick={() =>
                      setShowPassword({
                        ...showPassword,
                        confirmPassword: !showPassword.confirmPassword,
                      })
                    }
                  >
                    <KTIcon iconName='eye-slash' className='fs-2' />
                  </span>
                )}
                {formik.touched.confirmPassWord && formik.errors.confirmPassWord && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.confirmPassWord}</div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      {isEditMode && (
        <div className=' d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading}>
            {!loading && 'Save Changes'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      )}
    </form>
  );
}

export default UserProfileDetails;
