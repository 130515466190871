import axios from 'axios';
import {BASE_URL, FILTER_LIST, GET_DASHBOARD, GET_USER_DATA} from './Urls';

export class DashboardApiModule {
  async getAllDashboardData() {
    try {
      const response = await axios.get(BASE_URL + GET_DASHBOARD);
      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response.data?.data || [],
          message: response.data.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: [],
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: [],
        message: error.response.data.message || error.message,
      };
    }
  }
  async getUserFilterList(type: string, restaurantId: any) {
    try {
      const response = await axios.get(BASE_URL + FILTER_LIST(type), {
        params: {
          filterByRestaurantId: restaurantId,
        },
      });

      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response.data?.data,
          message: response.data.message,
        };
      }

      return {
        status: response.status,
        success: false,
        data: undefined,
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: undefined,
        message: error.response.data.message || error,
      };
    }
  }

  async getRestaurantFilterList(type: string, restaurantId: any) {
    try {
      const response = await axios.get(BASE_URL + FILTER_LIST(type), {
        params: {
          filterByUserId: restaurantId,
        },
      });

      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response.data?.data,
          message: response.data.message,
        };
      }

      return {
        status: response.status,
        success: false,
        data: undefined,
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: undefined,
        message: error.response.data.message || error,
      };
    }
  }

  async getUserData(token) {
    try {
      const response = await axios.get(BASE_URL + GET_USER_DATA, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response.data?.data || [],
          message: response.data.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: [],
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: [],
        message: error.response.data.message || error.message,
      };
    }
  }
}
