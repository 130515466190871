import axios from 'axios';
import {
  BASE_URL,
  TABLES_URL,
  ADD_TABLE_URL,
  UPDATE_TABLE_URL,
  RESTAURANTS_URL,
  RESTAURANT_CUSINE_URL,
  BOOKINGS_URL,
  STATUS_FILTER,
  SEARCH,
  PAGINATION,
  TRANSACTIONS_URL,
  RESTAURANT__UPDATE_URL,
  ADD_RESTAURANT_URL,
  RESTAURANT_ID_FILTER,
  USER_ID_FILTER,
  CAPACITY_FILTER,
  MIN_MAX_PRICE_FILTER,
  BOOKINGS_UPADATE_URL,
  DATE_FILTER,
  MANAGE_ATTENDANCE,
  RESTAURANT_PROFILE_URL,
  ATTENDANCE_FILTER,
  VERIFIED_FILTER,
  AUCTION_TYPE_LISTING,
  ADD_AUCTION_TYPE,
  DELETE_AUCTION_TYPE,
  RESTAURANT_SEND_OTP_VERIFICATION,
  RESTAURANT_VERIFY_OTP_VERIFICATION,
  GET_HOST_DETAILS,
  CREATE_HOST,
  ADD_HOST_CONTACT,
  EDIT_HOST_CONTACT,
  DELETE_HOST_CONTACT,
} from './Urls';
import {IParamsInfo} from './index.d';

export class RestaurantApiModule {
  constructor() {}

  async getSingleRestaurant(restaurantId) {
    try {
      const response = await axios.get(BASE_URL + RESTAURANTS_URL + '/' + restaurantId);
      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response.data?.data || [],
          message: response.data.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: [],
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: [],
        message: error.response.data.message || error.message,
      };
    }
  }

  async getRestaurantProfile() {
    try {
      const response = await axios.get(BASE_URL + RESTAURANT_PROFILE_URL);
      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response.data || {},
          message: response.data.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: [],
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: [],
        message: error.response.data.message || error.message,
      };
    }
  }
  async getRestaurant(paramInfo: IParamsInfo) {
    try {
      const response = await axios.get(
        BASE_URL +
          PAGINATION(RESTAURANTS_URL, paramInfo.pageNo, paramInfo?.pageSize) +
          STATUS_FILTER(paramInfo.statusFilter) +
          SEARCH(paramInfo.searchKeyword) +
          VERIFIED_FILTER(paramInfo.verifiedFilter) +
          DATE_FILTER(paramInfo?.startDate, paramInfo?.endDate)
      );
      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response.data?.data || [],
          message: response.data.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: [],
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: [],
        message: error.response.data.message || error.message,
      };
    }
  }

  async addRestaurant(restaurantData) {
    try {
      const response = await axios.post(BASE_URL + ADD_RESTAURANT_URL, restaurantData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: null,
          message: response.data?.data?.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: null,
        message: response.data?.data?.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: null,
        message: error.response.data.message || error.message,
      };
    }
  }
  async updateRestaurant(restaurantId, restaurantData) {
    try {
      const response = await axios.put(
        BASE_URL + RESTAURANT__UPDATE_URL + '/' + restaurantId,
        restaurantData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: null,
          message: response.data?.data?.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: null,
        message: response.data?.data?.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: null,
        message: error.response.data.message || error.message,
      };
    }
  }

  async getRestaurantTables(paramInfo: IParamsInfo) {
    try {
      const response = await axios.get(
        BASE_URL +
          PAGINATION(TABLES_URL, paramInfo.pageNo, paramInfo?.pageSize) +
          RESTAURANT_ID_FILTER(paramInfo?.restaurantIdFilter) +
          CAPACITY_FILTER(paramInfo?.capacity)
      );
      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response.data?.data || [],
          message: response.data.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: [],
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: [],
        message: error.response.data.message || error.message,
      };
    }
  }

  async addRestaurantTables(restaurantId, value) {
    const newVal: any = {capacity: value?.capacity};
    if (value?.restaurantId) {
      newVal.restaurantId = value?.restaurantId;
    }
    const url = BASE_URL + ADD_TABLE_URL;

    try {
      const response = await axios.post(url, newVal);
      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response.data?.data?.rows || [],
          message: response.data.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: [],
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: [],
        message: error.response.data.message || error.message,
      };
    }
  }

  async updateRestaurantTables(value) {
    const url = BASE_URL + UPDATE_TABLE_URL + '/' + value.id;
    const newVal = {capacity: value?.capacity, restaurantId: value?.restaurantId};
    try {
      const response = await axios.put(url, newVal);
      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response.data?.data?.rows || [],
          message: response.data.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: [],
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: [],
        message: error.response.data.message || error.message,
      };
    }
  }

  async getRestaurantsCusines() {
    try {
      const response = await axios.get(BASE_URL + RESTAURANT_CUSINE_URL);
      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response.data?.data || [],
          message: response.data.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: [],
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: [],
        message: error.response.data.message || error.message,
      };
    }
  }

  async updateRestaurantBookingsAttendence(paramInfo) {
    try {
      const response = await axios.put(BASE_URL + BOOKINGS_UPADATE_URL, paramInfo);
      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response.data?.data?.rows || [],
          message: response.data.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: [],
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: [],
        message: error.response.data.message || error.message,
      };
    }
  }

  async getRestaurantsBookings(paramInfo: IParamsInfo) {
    try {
      const response = await axios.get(
        BASE_URL +
          PAGINATION(BOOKINGS_URL, paramInfo.pageNo, paramInfo?.pageSize) +
          SEARCH(paramInfo?.searchKeyword) +
          USER_ID_FILTER(paramInfo?.userIdFilter) +
          RESTAURANT_ID_FILTER(paramInfo?.restaurantIdFilter) +
          MIN_MAX_PRICE_FILTER(paramInfo?.minPrice, paramInfo.maxPrice) +
          CAPACITY_FILTER(paramInfo?.capacity) +
          DATE_FILTER(paramInfo?.startDate, paramInfo?.endDate) +
          ATTENDANCE_FILTER(paramInfo?.attendance) +
          STATUS_FILTER(paramInfo?.statusFilter)
      );
      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response.data?.data || [],
          message: response.data.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: [],
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: [],
        message: error.response.data.message || error.message,
      };
    }
  }

  async markBookingAttendance(paramInfo) {
    try {
      const response = await axios.post(BASE_URL + MANAGE_ATTENDANCE, paramInfo);
      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response.data?.data || [],
          message: response.data.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: [],
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: [],
        message: error.response.data.message || error.message,
      };
    }
  }

  async getRestaurantsTransactions(paramInfo: IParamsInfo) {
    try {
      const response = await axios.get(
        BASE_URL + PAGINATION(TRANSACTIONS_URL, paramInfo.pageNo, paramInfo?.pageSize)
      );
      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response.data?.data || [],
          message: response.data.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: [],
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: [],
        message: error.response.data.message || error.message,
      };
    }
  }

  async deleteRestaurants(restaurantIds) {
    try {
      const response = await axios.delete(BASE_URL + RESTAURANTS_URL, {
        headers: {},
        data: restaurantIds,
      });
      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response.data?.data,
          message: response.data.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: undefined,
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: undefined,
        message: error.response.data.message || error.message,
      };
    }
  }

  async deleteSingleRestaurants(restaurantIds, token) {
    try {
      const response = await axios.delete(BASE_URL + RESTAURANTS_URL, {
        headers: {
          Authorization: `${token}`,
        },
        data: restaurantIds,
      });
      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response.data?.data,
          message: response.data.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: undefined,
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: undefined,
        message: error.response.data.message || error.message,
      };
    }
  }

  async deleteTables(tableIds) {
    try {
      const response = await axios.delete(BASE_URL + TABLES_URL, {headers: {}, data: tableIds});
      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response.data?.data,
          message: response.data.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: undefined,
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: undefined,
        message: error.response.data.message || error.message,
      };
    }
  }

  async getAuctionTypeListing(paramInfo: IParamsInfo) {
    try {
      const response = await axios.get(
        BASE_URL +
          PAGINATION(AUCTION_TYPE_LISTING, paramInfo.pageNo, paramInfo?.pageSize) +
          SEARCH(paramInfo.searchKeyword)
      );

      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response?.data?.data,
          message: response.data.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: undefined,
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: undefined,
        message: error.response.data.message || error.message,
      };
    }
  }

  async getAuctionTypeListingForRestaurant(paramInfo: IParamsInfo, restaurantId: string) {
    try {
      const response = await axios.get(
        BASE_URL +
          PAGINATION(AUCTION_TYPE_LISTING, paramInfo.pageNo, paramInfo?.pageSize) +
          SEARCH(paramInfo.searchKeyword) +
          `&restaurantId=${restaurantId}`
      );

      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response?.data?.data,
          message: response.data.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: undefined,
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: undefined,
        message: error.response.data.message || error.message,
      };
    }
  }

  async createAuctionType(userData) {
    try {
      const response = await axios.post(BASE_URL + ADD_AUCTION_TYPE, userData);

      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response.data.data,
          message: response.data?.data?.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: null,
        message: response.data?.data?.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: null,
        message: error.response.data.message || error.message,
      };
    }
  }

  async deleteAuctionType(auctionTypeId) {
    try {
      const response = await axios.delete(BASE_URL + DELETE_AUCTION_TYPE, {data: auctionTypeId});
      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response.data?.data,
          message: response.data.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: undefined,
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: undefined,
        message: error.response.data.message || error.message,
      };
    }
  }

  async sendOtp(mobileNumber: string) {
    try {
      const response = await axios.post(BASE_URL + RESTAURANT_SEND_OTP_VERIFICATION, {
        mobileNumber,
      });

      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response.data.data,
          message: response.data?.message,
        };
      }

      return {
        status: response.status,
        success: false,
        data: null,
        message: response.data?.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: null,
        message: error.response?.data?.message || error.message,
      };
    }
  }

  async verifyOtp(otp: string, hash: string) {
    try {
      const response = await axios.put(BASE_URL + RESTAURANT_VERIFY_OTP_VERIFICATION, {otp, hash});

      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response.data.data,
          message: response.data?.message,
        };
      }

      return {
        status: response.status,
        success: false,
        data: null,
        message: response.data?.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: null,
        message: error.response?.data?.message || error.message,
      };
    }
  }

  async addHost(hostData: any) {
    try {
      const response = await axios.post(BASE_URL + ADD_HOST_CONTACT, hostData);

      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response.data.data,
          message: response.data?.message,
        };
      }

      return {
        status: response.status,
        success: false,
        data: null,
        message: response.data?.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: null,
        message: error.response?.data?.message || error.message,
      };
    }
  }

  async editHost(id: string, hostData: any) {
    try {
      const response = await axios.put(`${BASE_URL + EDIT_HOST_CONTACT}/${id}`, hostData);

      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response.data.data,
          message: response.data?.message,
        };
      }

      return {
        status: response.status,
        success: false,
        data: null,
        message: response.data?.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: null,
        message: error.response?.data?.message || error.message,
      };
    }
  }

  async deleteHost(hostId) {
    try {
      const response = await axios.delete(`${BASE_URL + DELETE_HOST_CONTACT}/${hostId}`);
      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response.data?.data,
          message: response.data.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: undefined,
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: undefined,
        message: error.response.data.message || error.message,
      };
    }
  }

  async getHostDetails() {
    try {
      const response = await axios.get(BASE_URL + GET_HOST_DETAILS);
      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response.data?.data || [],
          message: response.data.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: [],
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: [],
        message: error.response.data.message || error.message,
      };
    }
  }

  async getHostDetailsForRestaurant(restaurantId) {
    try {
      const response = await axios.get(
        `${BASE_URL + GET_HOST_DETAILS}?restaurantId=${restaurantId}`
      );
      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response.data?.data || [],
          message: response.data.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: [],
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: [],
        message: error.response.data.message || error.message,
      };
    }
  }

  async createHost(hostData: any) {
    try {
      const response = await axios.post(BASE_URL + CREATE_HOST, hostData);
      if (response.data.success) {
        return {
          status: response.status,
          success: true,
          data: response.data?.data || [],
          message: response.data.message,
        };
      }
      return {
        status: response.status,
        success: false,
        data: [],
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        isNetwork: error.code === 'ERR_NETWORK',
        status: error?.response?.status,
        success: false,
        data: [],
        message: error.response.data.message || error.message,
      };
    }
  }
}
