import {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {KTIcon} from '../../../../_metronic/helpers';
import {PageTitle} from '../../../../_metronic/layout/core';
import DataTableWrapper from '../../../DataTable';
import {API_ERROR_MESSAGE, TOAST_GENERAL} from '../../../Helpers';
import {CustomModal} from '../../../components/Modal';
import {CommonViewInHeader} from '../../../components/common/DataTableViews';
import {useSessionReducer} from '../../../hook/useSessionReducer';
import {useAuth} from '../../../modules/auth';
import reducerFunc from '../../common/DataTableViews/Auction/action';

const initialState: any = {
  loading: true,
  data: [],
  totalData: -1,
  isModalOpen: false,
  modalType: 'RESTAURANT_MULTIPLE_MOB_NO',
  selectedRow: undefined,
  error: null,
};

const capitalize = (str) => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const HostDetails = ({restaurantId}) => {
  const [state, dispatch] = useSessionReducer<any, any, string>(reducerFunc, initialState, 'USER');
  const {restaurantModule} = useAuth();
  const [dayList, setDayList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);

  const getHostDetail = async function () {
    const result: any = await restaurantModule?.getHostDetailsForRestaurant(restaurantId);

    if (result?.success) {
      const updatedData = result.data?.rows?.map((item) => ({
        ...item,
        day: capitalize(item?.day),
        hostName: item?.hostName.split(' ').map(capitalize).join(' '),
      }));

      setDayList(updatedData);
    } else {
    }
  };

  useEffect(() => {
    getHostDetail();
  }, []);

  const subHeader = (
    <button
      type='button'
      className='btn btn-primary mt-2'
      onClick={() => {
        setIsEdit(false);
        dispatch({
          type: 'OPEN_MODAL',
          payload: 'RESTAURANT_MULTIPLE_MOB_NO',
        });
      }}
    >
      <KTIcon iconName='plus' className='fs-2' />
      Add Host
    </button>
  );

  let columns = [
    {
      name: <CommonViewInHeader name='Host Name' />,
      selector: (row, i) => row.hostName,
      sortable: true,
      width: '300px',
    },
    {
      name: <CommonViewInHeader name='Mobile Number' />,
      selector: (row, i) => row.mobileNumber,
      sortable: true,
      width: '300px',
    },
    {
      name: <CommonViewInHeader name='Day' />,
      selector: (row, i) => row.day,
      sortable: true,
      width: '300px',
    },
    {
      name: <CommonViewInHeader name='Action' />,
      selector: (row, i) => row.id,
      format: (row, i) => {
        return (
          <div className='d-flex align-items-center justify-content-center gap-2'>
            {/* Edit Action */}
            <span
              title='Edit host'
              className='menu-link px-3'
              onClick={() => {
                setIsEdit(true);
                dispatch({
                  type: 'OPEN_MODAL',
                  payload: 'RESTAURANT_MULTIPLE_MOB_NO',
                });
                dispatch({
                  type: 'SELECT_ROW',
                  payload: row,
                });
              }}
            >
              <KTIcon iconName='pencil' className='fs-1' />
            </span>

            {/* Delete Action */}
            <span
              title='Delete host'
              className='menu-link px-3'
              onClick={() => {
                dispatch({type: 'SELECT_ROW', payload: row});
                dispatch({type: 'OPEN_MODAL', payload: 'DELETE'});
              }}
            >
              <KTIcon iconName='trash' className='fs-1' />
            </span>
          </div>
        );
      },
      sortable: false,
    },
  ];

  const deleteHost = async (hostId) => {
    const result = await restaurantModule?.deleteHost(hostId);
    if (result?.success) {
      toast.success(`Host Deleted Successfully`, {
        toastId: TOAST_GENERAL,
      });
      getHostDetail();
    } else {
      toast.error(result?.message || API_ERROR_MESSAGE, {
        toastId: TOAST_GENERAL,
      });
    }
    dispatch({type: 'CLOSE_MODAL'});
  };

  const deleteItem = function (rows) {
    dispatch({type: 'SELECT_ROW', payload: rows});
    dispatch({type: 'OPEN_MODAL', payload: 'DELETE'});
  };

  return (
    <>
      <PageTitle>Manage Host</PageTitle>
      <DataTableWrapper
        subHeader={subHeader}
        pagination={false}
        columns={columns}
        data={dayList}
        handleSelected={deleteItem}
        selectableRows={true}
      />

      {state.isModalOpen && (
        <CustomModal
          handleCancelCallback={() => dispatch({type: 'CLOSE_MODAL'})}
          handleSaveCallback={async (values) => {
            console.log('values', values);

            if (state.modalType === 'DELETE') {
              await deleteHost(state?.selectedRow?.id);
            }
          }}
          data={{
            ...(state.selectedRow ? state.selectedRow : {}),
            restaurantId,
          }}
          type={state?.modalType}
          isEditMode={isEdit}
        />
      )}
    </>
  );
};

export default HostDetails;
